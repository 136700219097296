document.addEventListener('DOMContentLoaded', function () {
  function randomInteger(min, max) {
    let rand = min + Math.random() * (max - min)
    return Math.floor(rand)
  }

  // получение случайного числа
  function getRandomArbitrary(min, max) {
    return Math.floor(Math.random() * (max - min) + min)
  }

  // функция транслита
  function toTranslit(text) {
    return text.replace(/([а-яё])|([\s_-])|([^a-z\d])/gi, function (all, ch, space, words, i) {
      if (space || words) {
        return space ? '-' : ''
      }
      var code = ch.charCodeAt(0),
        index = code == 1025 || code == 1105 ? 0 : code > 1071 ? code - 1071 : code - 1039,
        t = [
          'yo',
          'a',
          'b',
          'v',
          'g',
          'd',
          'e',
          'zh',
          'z',
          'i',
          'y',
          'k',
          'l',
          'm',
          'n',
          'o',
          'p',
          'r',
          's',
          't',
          'u',
          'f',
          'h',
          'c',
          'ch',
          'sh',
          'shch',
          '',
          'y',
          '',
          'e',
          'yu',
          'ya'
        ]
      return t[index]
    })
  }

  // активируем промо слайдер
  if (document.querySelector('#promoSlider')) {
    const slidesCount = document.querySelectorAll('.promo__slide').length
    const randomSlide = randomInteger(0, slidesCount)

    const promoSlider = new Glide('#promoSlider', {
      type: 'carousel',
      perView: 3,
      autoplay: 8000,
      gap: 15,
      hoverpause: true,
      animationDuration: 500,
      animationTimingFunc: 'ease-in-out',
      startAt: randomSlide,
      breakpoints: {
        480: {
          perView: 1
        },
        768: {
          perView: 2
        }
      }
    })
    promoSlider.mount()
  }

  // работа дровера
  if (
    document.getElementById('headerBtn') &&
    document.querySelector('.blackscreen') &&
    document.querySelector('.header__drawer')
  ) {
    const headerBtn = document.getElementById('headerBtn')
    const blackscreen = document.querySelector('.blackscreen')
    const drawer = document.querySelector('.header__drawer')

    // Показываем дровер
    function showDrawer() {
      drawer.style.display = 'block'

      setTimeout(() => {
        blackscreen.classList.add('blackscreen--active')
        headerBtn.classList.add('header__menu--active')
        drawer.classList.add('header__drawer--active')
      }, 100)
    }

    // Убираем дровер
    function hideDrawer() {
      blackscreen.classList.remove('blackscreen--active')
      headerBtn.classList.remove('header__menu--active')
      drawer.classList.remove('header__drawer--active')

      setTimeout(() => {
        drawer.style.display = 'none'
      }, 500)
    }

    // Обработка клика на гамбургер
    headerBtn.addEventListener('click', function () {
      if (this.classList.contains('header__menu--active')) {
        hideDrawer()
      } else {
        showDrawer()
      }
    })

    blackscreen.addEventListener('click', function () {
      if (this.classList.contains('blackscreen--active')) {
        hideDrawer()
      } else {
        showDrawer()
      }
    })
  }

  // управляем сообщениями
  if (document.querySelector('.messages')) {
    const message = document.querySelector('.messages')
    const messageBtn = document.querySelector('.messages__cross')

    message.classList.add('messages--active')

    messageBtn.addEventListener('click', function () {
      message.classList.remove('messages--active')
    })

    setTimeout(() => {
      message.classList.remove('messages--active')
    }, 10000)
  }

  // управляем модалкой
  if (document.querySelectorAll('.vip__lead')) {
    const leadButtons = document.querySelectorAll('.vip__lead')
    const blackscreen = document.querySelector('.blackscreen')
    const formClose = document.querySelectorAll('.vip__form__close')

    leadButtons.forEach(function (element) {
      element.addEventListener('click', function (event) {
        const form = this.parentElement.parentElement.parentElement.querySelector('.vip__form')
        const captchaField = form.querySelector('input[name="captcha_1"]')
        captchaField.classList.add('vip__form__field')
        captchaField.classList.add('vip__form__field--empty')
        const formFields = form.querySelectorAll('.vip__form__field')
        const formBtn = form.querySelector('.vip__form__btn')

        event.preventDefault()

        blackscreen.classList.add('blackscreen--active')

        if (!form.classList.contains('vip__form--active')) {
          form.classList.add('vip__form--active')
        }

        // проверка формы
        setInterval(function () {
          // валидация полей
          formFields.forEach(function (element) {
            if (element.value === '' || element.value.length > 50) {
              element.classList.add('vip__form__field--empty')
            } else {
              element.classList.remove('vip__form__field--empty')
            }
          })

          const sizeEmpty = form.querySelectorAll('.vip__form__field--empty').length

          if (sizeEmpty > 0) {
            if (formBtn.classList.contains('vip__form__btn--disabled')) {
              return
            } else {
              formBtn.classList.add('vip__form__btn--disabled')
            }
          } else {
            formBtn.classList.remove('vip__form__btn--disabled')
          }
        }, 500)
      })
    })

    blackscreen.addEventListener('click', function () {
      const activeForm = document.querySelector('.vip__form--active')

      if (activeForm) {
        activeForm.classList.remove('vip__form--active')
      }

      if (blackscreen) {
        blackscreen.classList.remove('blackscreen--active')
      }
    })

    formClose.forEach(function (element) {
      element.addEventListener('click', function () {
        const activeForm = document.querySelector('.vip__form--active')

        if (activeForm) {
          activeForm.classList.remove('vip__form--active')
        }

        if (blackscreen) {
          blackscreen.classList.remove('blackscreen--active')
        }
      })
    })
  }

  // активируем категории
  if (document.querySelector('.categories__accordion')) {
    new Accordion('.categories__accordion', {})
  }

  // калькулятор
  if (document.querySelector('.calculator')) {
    const calculator = document.querySelector('.calculator')

    calculator.addEventListener('input', function (event) {
      // отображаем значение ползунка
      const element = event.target
      const number = element.previousElementSibling.querySelector('span')
      number.innerText = element.value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')

      // считаем платеж
      const sum = document.getElementById('rangeSum').value
      const time = document.getElementById('rangeTime').value
      const percent = document.getElementById('rangePercent').value
      const resultMonthPay = document.getElementById('resultMonthPay')
      const resultOverpayment = document.getElementById('resultOverpayment')
      const resultTotalPayment = document.getElementById('resultTotalPayment')

      const i = percent / 12 / 100
      const koef = (i * Math.pow(1 + i, time * 12)) / (Math.pow(1 + i, time * 12) - 1)

      let payPerMonth = sum * koef
      payPerMonth = payPerMonth.toFixed()

      // Считаем переплату
      let overpayment = payPerMonth * (time * 12) - sum

      // Считаем переплату
      let totalPayment = parseInt(sum) + parseInt(overpayment)

      resultMonthPay.querySelector('span').innerText = payPerMonth.replace(
        /(\d)(?=(\d\d\d)+([^\d]|$))/g,
        '$1 '
      )
      resultOverpayment.querySelector('span').innerText = overpayment
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
      resultTotalPayment.querySelector('span').innerText = totalPayment
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
    })
  }

  // прокрутка наверх
  if (document.querySelector('.arrow')) {
    const adsArrow = document.querySelector('.arrow')

    if (window.pageYOffset >= 1000) {
      if (adsArrow.style.opacity != 1) {
        adsArrow.style.opacity = 1
        adsArrow.style.zIndex = 1
      }
    } else {
      adsArrow.style.opacity = 0
      adsArrow.style.zIndex = -5
    }

    // Добавление кнопки прокрутки наверх
    window.addEventListener('scroll', function () {
      if (window.pageYOffset >= 1000) {
        if (adsArrow.style.opacity != 1) {
          adsArrow.style.opacity = 1
          adsArrow.style.zIndex = 1
        }
      } else {
        adsArrow.style.opacity = 0
        adsArrow.style.zIndex = -5
      }
    })

    // скролл по клику
    adsArrow.addEventListener('click', function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
  }

  // список городов
  if (document.getElementById('formRegions')) {
    const formRegions = new Choices('#formRegions', {
      removeItemButton: true,
      noResultsText: 'Выберите города из списка',
      noChoicesText: 'Все города уже выбраны',
      itemSelectText: 'Добавить город',
      placeholder: true,
      placeholderValue: 'Выберите города'
    })
  }

  // список ключевиков
  if (document.getElementById('formTags')) {
    const formTags = new Choices('#formTags', {
      removeItemButton: true,
      noResultsText: 'Выберите ключевые слова из списка',
      noChoicesText: 'Все ключевые слова уже выбраны',
      itemSelectText: 'Добавить ключ',
      placeholder: true,
      placeholderValue: 'Выберите ключ'
    })
  }

  // валидация формы объявления
  if (document.querySelector('.addform')) {
    const form = document.querySelector('.addform')
    const title = form.querySelector('#createFormTitle')
    const slug = form.querySelector('#createFormSlug')
    const textarea = form.querySelector('textarea.addform__input')
    const counter = form.querySelector('.addform__description-addinfo span')

    const addFormInputs = form.querySelectorAll('.addform__input')

    textarea.addEventListener('keyup', function () {
      counter.textContent = 1000 - this.value.length
    })

    const addFormBtn = form.querySelector('.addform__btn')

    title.addEventListener('change', function () {
      slug.value = `${getRandomArbitrary(1, 999999)}-${toTranslit(this.value)}`
    })

    const addFormCaptcha = form.querySelector('.addform__bottom-captcha input[type="text"]')

    setInterval(function () {
      // валидация полей
      addFormInputs.forEach(function (element) {
        if (element.value === '' || element.value.length <= 1 || element.value.length > 1000) {
          element.classList.add('addform__input--empty')
        } else {
          element.classList.remove('addform__input--empty')
        }

        if (addFormCaptcha.value === '' || addFormCaptcha.value.length <= 0) {
          addFormCaptcha.classList.add('addform__input--empty')
        } else {
          addFormCaptcha.classList.remove('addform__input--empty')
        }

        const sizeEmpty = form.querySelectorAll('.addform__input--empty').length

        if (sizeEmpty > 0) {
          if (addFormBtn.classList.contains('addform__btn--disabled')) {
            return
          } else {
            addFormBtn.classList.add('addform__btn--disabled')
          }
        } else {
          addFormBtn.classList.remove('addform__btn--disabled')
        }
      })
    }, 500)
  }

  // Удлинняем кнопку на ВИПах
  if (document.querySelectorAll('.vip__item')) {
    const vip_ads = document.querySelectorAll('.vip__item')

    vip_ads.forEach(function (element) {
      const buttonsNode = element.querySelector('.vip__buttons').querySelectorAll('.vip__button')

      const buttons = Array.from(buttonsNode)

      if (buttons.length % 2 !== 0) {
        const lastElement = buttons.pop()

        lastElement.style.flexBasis = '100%'
      }
    })
  }

  // Удлинняем кнопку внутри объявления
  if (document.querySelector('.vip__buttons')) {
    const buttonsNode = document.querySelector('.vip__buttons').querySelectorAll('.vip__button')

    const buttons = Array.from(buttonsNode)

    if (buttons.length % 2 !== 0) {
      const lastElement = buttons.pop()

      lastElement.style.flexBasis = '100%'
    }
  }

  // проверяем форму с комментарием
  if (document.querySelector('.comments__form')) {
    const commentsForm = document.querySelector('.comments__form')
    const commentsFormInputs = commentsForm.querySelectorAll('.comments__field')
    const commentsFormTextarea = commentsForm.querySelector('.comments__textarea')
    const commentsFormCaptcha = commentsForm.querySelector('.comments__captcha input[type="text"]')

    const commentsFormBtn = commentsForm.querySelector('.comments__btn')

    setInterval(function () {
      // валидация полей
      commentsFormInputs.forEach(function (element) {
        if (element.value === '' || element.value.length <= 2 || element.value.length > 30) {
          element.classList.add('comments__field--empty')
        } else {
          element.classList.remove('comments__field--empty')
        }

        if (
          commentsFormTextarea.value === '' ||
          commentsFormTextarea.value.length <= 5 ||
          commentsFormTextarea.value.length > 1000
        ) {
          commentsFormTextarea.classList.add('comments__field--empty')
        } else {
          commentsFormTextarea.classList.remove('comments__field--empty')
        }

        if (commentsFormCaptcha.value === '' || commentsFormCaptcha.value.length <= 0) {
          commentsFormCaptcha.classList.add('comments__field--empty')
        } else {
          commentsFormCaptcha.classList.remove('comments__field--empty')
        }

        const sizeEmpty = commentsForm.querySelectorAll('.comments__field--empty').length

        if (sizeEmpty > 0) {
          if (commentsFormBtn.classList.contains('comments__btn--disabled')) {
            return
          } else {
            commentsFormBtn.classList.add('comments__btn--disabled')
          }
        } else {
          commentsFormBtn.classList.remove('comments__btn--disabled')
        }
      })
    }, 500)
  }

  // Добавляем порядковые номера для VIP-объявлений
  const vipAds = document.querySelectorAll('.vip__item')

  if (vipAds.length > 0) {
    vipAds.forEach((vipAd, index) => {
      // Находим элемент для порядкового номера
      const positionIndex = vipAd.querySelector('.vip__position-index')

      // Проверяем наличие элемента перед попыткой записи
      if (positionIndex) {
        positionIndex.textContent = index + 1
      } else {
        console.warn(`Элемент .vip__position-index не найден в одном из .vip__item.`)
      }
    })
  }
})
